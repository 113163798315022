// @ts-nocheck
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MultilangTextareaModel } from './multilang-textarea.model';
import { MultilangInputModel } from '../multilang-input/multilang-input.model';
import { Subject } from 'rxjs';
import { Language } from '@api/models/Postgres/Model/language';

/**
 * Multilang textarea
 */
@Component({
    selector: 'ui-multilang-textarea',
    templateUrl: './multilang-textarea.component.html',
    styleUrls: ['./multilang-textarea.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultilangTextareaComponent),
            multi: true,
        },
    ],
})
/**
 * Multiline text field for filling in form fields that require translations in different languages in the database
 */
export class MultilangTextareaComponent
    implements OnChanges, OnDestroy, AfterViewInit {
    /**
     * Required flag
     */
    @Input() required: boolean;
    /**
     * Label text
     */
    @Input() label: string;
    /**
     * Data for fill
     */
    @Input() patch: MultilangInputModel[];
    /**
     * Disable flag
     */
    @Input() disabledControl: boolean;
    /**
     * Tinymce editor enable flag
     */
    @Input() editorEnable: boolean;

    @Input() langForEditor: string;
    /**
     * Selected language code
     */
    selectedLang: string;
    /**
     * Form control
     */
    control: FormControl = null;
    /**
     * Changed flag
     */
    changed: boolean;
    /**
     * Form control
     */
    form: FormGroup = this.fb.group({}, {});
    /**
     * List languages  (Auxiliary)
     */
    languagesValue: Language[];
    /**
     * Destroy var for unsubscribe
     */
    private readonly destroy$: Subject<unknown> = new Subject<unknown>();

    constructor(
        private fb: FormBuilder,
        private injector: Injector,
        private cd: ChangeDetectorRef,
    ) {
    }

    /**
     * List languages
     */
    @Input() set languages(languages: Language[]) {
        if (languages) {
            this.selectedLang = languages[0].code;
            this.makeForm(languages);
            this.languagesValue = languages;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.patch && this.languagesValue) {
            setTimeout(
                () =>
                    this.patch.forEach((val: MultilangInputModel) =>
                        this.form.patchValue({
                            [val.lang]: val.value,
                        }),
                    ),
                1000,
            );
        }
    }

    ngAfterViewInit(): void {
        const ngControl: NgControl = this.injector.get<any>(NgControl as any, null);
        if (ngControl) {
            this.control = ngControl.control as FormControl;
            this.control.statusChanges
                .pipe(
                    distinctUntilChanged((a, b) => a === b),
                    takeUntil(this.destroy$),
                )
                .subscribe((status) => {
                    this.cd.markForCheck();
                });
        } else {
            console.warn('Input is not defined');
        }
    }

    propagateChange = (_: string) => {
    };
    touchedChange = (_: string) => {
    };

    patchValue(value: string): void {
        setTimeout(() => {
            this.cd.markForCheck();
        }, 0);
    }

    onChange(value) {
        this.change(value);
    }

    onBlur(value) {
        this.changed = true;
        this.touchedChange('');
    }

    onKeyUp(value) {
        this.change(value.target.value);
    }

    change(value) {
        this.cd.markForCheck();
        this.propagateChange(value);
    }

    writeValue(value: string): void {
        this.patchValue(value);
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any): void {
        this.touchedChange = fn;
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    private makeForm(languages: LangModel[]): void {
        const controls = {};
        languages.map((lang) => {
            controls[lang.code] = this.fb.control(
                null,
                this.required ? [Validators.required] : [],
            );
        });
        this.form = this.fb.group(controls, {});
        this.form.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((values) => {
                const prepareValues: MultilangTextareaModel[] = [];
                for (const key in values) {
                    if (values.hasOwnProperty(key)) {
                        prepareValues.push({
                            lang: key.toLowerCase(),
                            value: values[key],
                        });
                    }
                }
                this.change(prepareValues);
            });
    }
}
