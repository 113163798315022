import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { Observable } from 'rxjs';
import { ReportService } from '@services/report/report.service';
import { ReportBody, ReportDetailModel, ReportModel } from '@services/report/report.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReportDashboardMode } from '@services/report-folder/report-folder.model';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { PushService } from '@core/push/push.service';
import { PushCodeModel } from '@core/push/push-code.model';
import { Language } from '@api/models/Postgres/Model/language';
import { LanguageState } from '@core/localization/language.state';


@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'rep-popup-copy-report',
    templateUrl: './popup-copy-report.component.html',
    styleUrls: ['./popup-copy-report.component.scss'],
})
export class PopupCopyReportComponent {
    readonly #push: PushService = inject(PushService);
    readonly #languageState: LanguageState = inject(LanguageState);

    public readonly report: ReportModel = this.data.params?.serviceParams?.report;
    public readonly mode: ReportDashboardMode = this.data.params?.serviceParams?.mode;

    service: ReportService | DashboardService =
        this.mode === 'report' ? this.reportService : this.dashboardService;

    public readonly languages$: Observable<Language[]> = this.#languageState.languages$;
    public readonly pending$: Observable<boolean> = this.service.pending;

    public readonly reportDetail$: Observable<ReportDetailModel> = this.service.getById(
        this.report.key,
    );

    constructor(
        private dialogRef: MatDialogRef<PopupCopyReportComponent>,
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private reportService: ReportService,
        private dashboardService: DashboardService,
    ) {
    }

    handlerCopyReport(body: ReportBody): void {
        this.service
            .copy(body)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.#push.success(PushCodeModel.SuccessAdd);
                this.dialogRef.close();
            });
    }
}
