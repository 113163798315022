import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CommonAppModule } from '@ui/common-app/common-app.module';
import { CommonAppComponent } from '@ui/common-app/common-app.component';
import { PopupContainersModule } from './popup-containers/popup-containers.module';
import { ThirdLevelMenuFactory } from '@libs/menu/third-level-menu.service';
import { thirdLevelMenu } from './sub.menu';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';


export const loadSubMenu =
    (thirdLevelMenuFactory: ThirdLevelMenuFactory) => () =>
        thirdLevelMenuFactory.loadMenu(thirdLevelMenu);

@NgModule({
    declarations: [],
    imports: [
        AppRoutingModule,
        CommonAppModule,
        PopupContainersModule,
        EditorModule,
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        {
            provide: APP_INITIALIZER,
            useFactory: loadSubMenu,
            deps: [ThirdLevelMenuFactory],
            multi: true,
        },
    ],
    bootstrap: [CommonAppComponent],
})
export class AppModule {
}
