<form [formGroup]="form">
    <ui-multilang-input [class.mb-0]="copyMode"
                        [customClass]="copyMode ? 'mb-0' : 'mb-5'"
                        [label]="'global.forms.labels.name'"
                        [languages]="languages"
                        [formControlName]="'names'"
                        [patch]="names"
                        [disabledControl]="lyView"
                        [required]="true">

    </ui-multilang-input>

    <ui-multilang-textarea *ngIf="!copyMode"
                           class="d-block mb-5"
                           [disabledControl]="lyView"
                           [required]="true"
                           [label]="'global.forms.labels.description'"
                           [languages]="languages"
                           [patch]="descriptions"
                           [langForEditor]="lang | convertLangForTinymce"
                           [editorEnable]="true"
                           [formControlName]="'infos'">

    </ui-multilang-textarea>

    <ui-form-group-wrapper *ngIf="!copyMode"
                           [required]="true"
                           [label]="'global.labels.folder' | translate">
        <ng-select [clearAllText]="'global.labels.ng-select.clear' | translate"
                   [notFoundText]="'global.labels.no_items_found' | translate"
                   [class.ng-select--loading]="!displayFolders"
                   [searchable]="false"
                   [multiple]="true"
                   [readonly]="lyView"
                   [items]="displayFolders"
                   [ngClass]="'ng-select--form-control'"
                   [formControlName]="'folders'"
                   bindLabel="folderName"
                   bindValue="folderId">
            <ng-template ng-option-tmp let-item="item">
                <span [ngClass]="'ps-' + item?.level * 3"></span>{{ item?.folderName }}
            </ng-template>
        </ng-select>
    </ui-form-group-wrapper>

    <ui-form-group-wrapper *ngIf="!copyMode"
                           [required]="true"
                           [label]="'ui.user-form-roles.head' | translate">
        <ng-select [clearAllText]="'global.labels.ng-select.clear' | translate"
                   [notFoundText]="'global.labels.no_items_found' | translate"
                   [class.ng-select--loading]="!roles"
                   [searchable]="false"
                   [multiple]="true"
                   [readonly]="lyView"
                   [items]="roles"
                   [ngClass]="'ng-select--form-control'"
                   [formControlName]="'roles'"
                   bindLabel="name"
                   bindValue="roleId">

        </ng-select>
    </ui-form-group-wrapper>

    <ui-button-row [lyView]="lyView">
        <ui-button *ngIf="!copyMode"
                   [size]="'lg'"
                   [disabled]="form?.status === 'INVALID' || pending || !form?.dirty"
                   [label]="key ? 'global.labels.save' : 'global.actions.add'"
                   (handlerClick)="handlerSubmit()">

        </ui-button>
        <ui-button *ngIf="copyMode"
                   [disabled]="form?.status === 'INVALID' || pending || !form?.dirty"
                   [label]="'global.labels.copy'"
                   (handlerClick)="handlerSubmit()">

        </ui-button>
    </ui-button-row>
</form>
