import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportFolderBody, ReportFolderDetail, ReportFolderModel } from '@services/report-folder/report-folder.model';
import { FormBuilder, Validators } from '@angular/forms';
import { MultilangInputModel } from '@ui/multilang-input/multilang-input.model';
import { Language } from '@api/models/Postgres/Model/language';


/**
 * Form add/edit report folder
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-form-report-folder',
    templateUrl: './form-report-folder.component.html',
    styleUrls: ['./form-report-folder.component.scss'],
})
export class FormReportFolderComponent {
    /**
     * Add mode flag
     */
    @Input() addMode: boolean;
    /**
     * Read-only mode flag
     */
    @Input() lyView: boolean;
    /**
     * Server pending flag
     */
    @Input() pending: boolean;
    /**
     * List languages
     */
    @Input() languages: Language[];
    /**
     * Submit form event
     *
     * @returns Form data
     */
    @Output() submitForm: EventEmitter<ReportFolderBody> =
        new EventEmitter<ReportFolderBody>();
    /**
     * Form object
     */
    readonly form = this.fb.group(
        {
            names: this.fb.control(null, [Validators.required]),
            pFolderId: this.fb.control(null, []),
            folderId: this.fb.control(null, []),
        },
        {},
    );
    /**
     * Name Multilang patch variable
     */
    names: MultilangInputModel[];

    constructor(private fb: FormBuilder) {
    }

    /**
     * Folder data
     *
     * @param folder
     */
    @Input() set folder(folder: ReportFolderModel) {
        if (folder) {
            if (this.addMode) {
                this.form.patchValue({ pFolderId: folder.folderId });
            } else {
                this.form.patchValue(folder);
            }
        }
    }

    /**
     * Folder detail data
     *
     * @param folderData
     */
    @Input() set folderData(folderData: ReportFolderDetail) {
        if (folderData) {
            this.form.patchValue({ names: folderData.names });
            this.names = folderData.names;
        }
    }

    handlerSubmit(): void {
        this.submitForm.emit(this.form.getRawValue());
    }
}
